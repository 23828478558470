import DTCLogo from '../../assets/images/logoblanco 3.svg';
import PFLogo from '../../assets/images/Group 43.png';
import POCLogo from '../../assets/images/Group 45.png';
import UNIQUELogo from '../../assets/images/Capa 1.png';
import MFLogo from '../../assets/images/logosMinterFactory-02 1.png';
import MF from '../../assets/images/celulares/04b.png';
import DTC from '../../assets/images/celulares/02b.png';
import POC from '../../assets/images/celulares/01b.png';
import UNIQUE from '../../assets/images/celulares/05b.png';
import PF from '../../assets/images/celulares/03b.png';

import PopUpPOCLogo from '../../assets/images/popup/cake.png';
import PopUpPOCCellphones from '../../assets/images/popup/Celular_cake_sinbg.png';
import PopUpPOCHome1 from '../../assets/images/popup/CAKE01.png';
import PopUpPOCHome2 from '../../assets/images/popup/CAKE02.png';
import PopUpPOCHome3 from '../../assets/images/popup/CAKE03.png';
import PopUpPOCHome4 from '../../assets/images/popup/CAKE04.png';

import PopUpDTCLogo from '../../assets/images/popup/DTC.png';
import PopUpDTCCellphones from '../../assets/images/popup/Celular_DTC_sinbg.png';
import PopUpDTCHome1 from '../../assets/images/popup/DTC01.png';
import PopUpDTCHome2 from '../../assets/images/popup/DTC02.png';
import PopUpDTCHome3 from '../../assets/images/popup/DTC03.png';

import PopUpPFLogo from '../../assets/images/popup/perception.png';
import PopUpPFCellphones from '../../assets/images/popup/perception_sinbg.png';
import PopUpPFHome1 from '../../assets/images/popup/PERCEPTION01.png';
import PopUpPFHome2 from '../../assets/images/popup/PERFRCTIO02.png';

import PopUpMFLogo from '../../assets/images/popup/minter.png';
import PopUpMFCellphones from '../../assets/images/popup/minter_sinbg.png';
import PopUpMFHome1 from '../../assets/images/popup/MINTER01.png';
import PopUpMFHome2 from '../../assets/images/popup/MINTER02.png';

import PopUpUNIQUELogo from '../../assets/images/popup/unique.png';
import PopUpUNIQUECellphones from '../../assets/images/popup/unique_sinbg.png';
import PopUpUNIQUEHome1 from '../../assets/images/popup/UNIQUE01.png';
import PopUpUNIQUEHome2 from '../../assets/images/popup/UNIQUE02.png';

import { Container } from 'react-bootstrap';
import './services-carousel.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards } from "swiper";

import ModalClient from '../modals/modal-client/modal-client';

import React, { useState } from 'react';

const ServicesCarousel = () => {

    const handleShow = (cellphones, logo, firstDesktop, secondDesktop, thirdDesktop, fourthDesktop, urlSite) => {
        setCellphones(cellphones)
        setLogo(logo)
        setFirstDesktop(firstDesktop)
        setSecondDesktop(secondDesktop)
        setThirdDesktop(thirdDesktop)
        setFourthDesktop(fourthDesktop)
        setUrlSite(urlSite)
        setShowModalClient(true);
    }

    const [showModalClient, setShowModalClient] = useState(false);

    const [logo, setLogo] = useState();
    const [cellphones, setCellphones] = useState();
    const [firstDesktop, setFirstDesktop] = useState();
    const [secondDesktop, setSecondDesktop] = useState();
    const [thirdDesktop, setThirdDesktop] = useState();
    const [fourthDesktop, setFourthDesktop] = useState();
    const [urlSite, setUrlSite] = useState('');

    // setFirstDesktop(firstDesktop)
    // setSecondDesktop(secondDesktop)
    // setThirdDesktop(thirdDesktop)
    // setFourthDesktop(fourthDesktop)

    const urlPOC = 'https://pieceofcakewallet.com/'
    const urlDTC = 'https://digitaltradingcards.com/'
    const urlPF = 'https://perceptionface.com/'
    const urlMF = 'https://minterfactory.io/'
    const urlUNIQUE = 'https://www.bankunique.com/'

    return (
        <>
            <ModalClient
                show={showModalClient}
                onHide={() => setShowModalClient(false)}
                cellphones={cellphones}
                logo={logo}
                firstDesktop={firstDesktop}
                secondDesktop={secondDesktop}
                thirdDesktop={thirdDesktop}
                fourthDesktop={fourthDesktop}
                urlSite={urlSite}
            />
            <div className='servicesandclients'>
                <Container>
                    <h2>CLIENTS & PRODUCTS </h2>
                    <h6 className='subtitle'> Transform your ideas into a concrete and viable Blockchain project. </h6>
                    <div className='services-carousel d-none d-lg-block'>
                        <div className='acomodar-fotos-celulares foto-grande'>
                            <div onClick={() => handleShow(PopUpPOCCellphones, PopUpPOCLogo, PopUpPOCHome1, PopUpPOCHome2, PopUpPOCHome3, PopUpPOCHome4, urlPOC)}>
                                <img src={POC} alt="" className="foto" />
                                <img src={POCLogo} alt="" className="foto" />
                            </div>
                            <div onClick={() => handleShow(PopUpDTCCellphones, PopUpDTCLogo, PopUpDTCHome1, PopUpDTCHome2, PopUpDTCHome3, '', urlDTC)}>
                                <img src={DTC} alt="" className="foto" />
                                <img src={DTCLogo} alt="" className="foto" />
                            </div>
                            <div onClick={() => handleShow(PopUpPFCellphones, PopUpPFLogo, PopUpPFHome1, PopUpPFHome2, '', '', urlPF)}>
                                <img src={PF} alt="" className="foto" />
                                <img src={PFLogo} alt="" className="foto" />
                            </div>
                            <div onClick={() => handleShow(PopUpMFCellphones, PopUpMFLogo, PopUpMFHome1, PopUpMFHome2, '', '', urlMF)}>
                                <img src={MF} alt="" className="foto" />
                                <img src={MFLogo} alt="" className="foto" />
                            </div>
                            <div onClick={() => handleShow(PopUpUNIQUECellphones, PopUpUNIQUELogo, PopUpUNIQUEHome1, PopUpUNIQUEHome2, '', '', urlUNIQUE)}>
                                <img src={UNIQUE} alt="" className="foto" />
                                <img src={UNIQUELogo} alt="" className="foto" />
                            </div>
                        </div>
                    </div>
                    <div className='services-carousel d-lg-none'>
                        <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]} className="mySwiper">
                            <SwiperSlide>
                                <div>
                                    <img src={POC} alt="" className="foto" />
                                    <img src={POCLogo} onClick={() => handleShow(PopUpPOCCellphones, PopUpPOCLogo, PopUpPOCHome1, PopUpPOCHome2, PopUpPOCHome3, PopUpPOCHome4, urlPOC)} alt="" className="foto" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src={DTC} alt="" className="foto" />
                                    <img src={DTCLogo} onClick={() => handleShow(PopUpDTCCellphones, PopUpDTCLogo, PopUpDTCHome1, PopUpDTCHome2, PopUpDTCHome3, '', urlDTC)} alt="" className="foto" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src={PF} alt="" className="foto" />
                                    <img src={PFLogo} onClick={() => handleShow(PopUpPFCellphones, PopUpPFLogo, PopUpPFHome1, PopUpPFHome2, '', '', urlPF)} alt="" className="foto" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src={MF} alt="" className="foto" />
                                    <img src={MFLogo} onClick={() => handleShow(PopUpMFCellphones, PopUpMFLogo, PopUpMFHome1, PopUpMFHome2, '', '', urlMF)} alt="" className="foto" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src={UNIQUE} alt="" className="foto" />
                                    <img src={UNIQUELogo} onClick={() => handleShow(PopUpUNIQUECellphones, PopUpUNIQUELogo, PopUpUNIQUEHome1, PopUpUNIQUEHome2, '', '', urlUNIQUE)} alt="" className="foto" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                </Container>
            </div>


        </>

    )
}

export default ServicesCarousel


