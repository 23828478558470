import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./body-intro.css";
import Web from '../../assets/Iphones04-v1.glb'
import Poster from '../../assets/images/celulares02.png'


const BodyIntro = () => {

  return (
    <div className="body-intro achicar-body-intro" id="home">
      <Container>
        <Row className="body-row">
          <Col md={6} xs={12} className="body-text">
            <h3>We build solutions on the</h3>
            <h1>BLOCKCHAIN</h1>
            <p>
              We are a knowledge company focused on building solutions on
              Blockchain.
            </p>
            <div className="boton-colorido">
              
                <button className="buttont" onClick={() => window.location.replace("/#contact")}>TELL US YOUR IDEA</button>
              
            </div>
          </Col>
          <Col md={6} xs={12} > 
            <model-viewer
          src={Web}
          /* ios-src={WebIos} */
          ar
          ar-modes="scene-viewer webxr"
          poster={Poster}
          camera-controls
          touch-action="pan-y"
          xr-environment
          camera-target="auto"
          camera-orbit="90deg 90deg 90"
          style={{width: '100%', height: '100%'}}
          auto-rotate
          scale=" 0.4 0.4 0.4"
        ></model-viewer>
          </Col>
        </Row>
        
        {/* <img src={Home} alt="" className="fondoHome" /> */}
      </Container>
    </div>
  );
};


export default BodyIntro;
