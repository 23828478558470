import React from 'react'
import './cards.css';
import Mail from '../../assets/images/mail.png';
import Minero from '../../assets/images/Minero.png';
import TuercaLampara from '../../assets/images/TuercaLampara.png';
import Billetera from '../../assets/images/Billetera.png';
import Casa from '../../assets/images/casa.png';
import Clic from '../../assets/images/clic.png';
import Certificado from '../../assets/images/certificado.png';
import User from '../../assets/images/user.png';
import Chango from '../../assets/images/chango.png';
import { Container, Row, Col } from 'react-bootstrap';


const Cards = () => {
    return (
        <Container className='cards-section'>
            <Row lg={3} md={2} xs={1} className='corregir-margin'>
                <Col>
                    <div className='contenedor'>
                        <div className='contenedor_tarjeta'>
                            <div className='remplazohref'>
                                <figure>
                                    <div className='frontal'>
                                        <img src={User} className='fluid' alt="card-user" />
                                        <h4>TECHNOLOGY <br></br>CONSULTING</h4>
                                    </div>
                                    <figcaption className='trasera'>
                                        {/* <h2>TECHNOLOGY <br></br>CONSULTING</h2> */}
                                        <p>
                                            Consulting service in the development of decentralized applications (DApps) on the blockchain
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>

                </Col>
                <Col>
                    <div className='contenedor'>
                        <div className='contenedor_tarjeta'>
                            <div className='remplazohref'>
                                <figure>
                                    <div className='frontal'>
                                        <img src={Chango} className='fluid' alt="card-user" />
                                        <h4>Personalized <br></br>NFT Market Places</h4>
                                    </div>
                                    <figcaption className='trasera'>
                                        {/* <h2>TECHNOLOGY <br></br>CONSULTING</h2> */}
                                        <p>
                                            Add value to your NFT collection by exchanging them on your own personalized marketplace.
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>

                </Col>
                <Col>
                    {/* <Card>
                        <div>
                            <Card.Img variant="top" src={Certificado} />
                        </div>
                        <Card.Body>
                            <Card.Title>Blockchain <br></br>Certificates</Card.Title>
                            <Card.Text>
                                Issue secure and anti fraud digital credentials verifiable and auditable.
                            </Card.Text>
                        </Card.Body>
                    </Card> */}

                    <div className='contenedor'>
                        <div className='contenedor_tarjeta'>
                            <div className='remplazohref'>
                                <figure>
                                    <div className='frontal'>
                                        <img src={Certificado} className='fluid' alt="card-user" />
                                        <h4>Blockchain <br></br>Certificates</h4>
                                    </div>
                                    <figcaption className='trasera'>
                                        {/* <h2>TECHNOLOGY <br></br>CONSULTING</h2> */}
                                        <p>
                                            Issue secure and anti fraud digital credentials verifiable and auditable.
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    {/* <Card>
                        <div>
                            <Card.Img variant="top" src={Clic} />
                        </div>
                        <Card.Body>
                            <Card.Title>User Friendly <br></br>Wallet</Card.Title>
                            <Card.Text>
                                Frictionless Experience. View, Create and Transfer NFT. Easy Login, no onboarding. Simple and Secure.
                            </Card.Text>
                        </Card.Body>
                    </Card> */}

                    <div className='contenedor'>
                        <div className='contenedor_tarjeta'>
                            <div className='remplazohref'>
                                <figure>
                                    <div className='frontal'>
                                        <img src={Clic} className='fluid' alt="card-user" />
                                        <h4>User Friendly <br></br>Wallet</h4>
                                    </div>
                                    <figcaption className='trasera'>
                                        <p>
                                            Frictionless Experience. View, Create and Transfer NFT. Easy Login, no onboarding. Simple and Secure.
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    {/* <Card>
                        <div>
                            <Card.Img variant="top" src={Mail} />
                        </div>
                        <Card.Body>
                            <Card.Title>Encrypted <br></br>Blockchain  Email</Card.Title>
                            <Card.Text>
                                Send and receive secure encrypted email using blockchain tecnology
                            </Card.Text>
                        </Card.Body>
                    </Card> */}


                    <div className='contenedor'>
                        <div className='contenedor_tarjeta'>
                            <div className='remplazohref'>
                                <figure>
                                    <div className='frontal'>
                                        <img src={Mail} className='fluid' alt="card-user" />
                                        <h4>Encrypted <br></br>Blockchain  Email</h4>
                                    </div>
                                    <figcaption className='trasera'>
                                        <p>
                                        Send and receive secure encrypted email using blockchain tecnology
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    {/* <Card>
                        <div>
                            <Card.Img variant="top" src={Casa} />
                        </div>
                        <Card.Body>
                            <Card.Title>Financial Assets<br></br> Tokenization</Card.Title>
                            <Card.Text>
                                Tokenize your financial assets and get access to better investments on Real Estate, Equity Shares, Gold and more.
                            </Card.Text>
                        </Card.Body>
                    </Card> */}

                    <div className='contenedor'>
                        <div className='contenedor_tarjeta'>
                            <div className='remplazohref'>
                                <figure>
                                    <div className='frontal'>
                                        <img src={Casa} className='fluid' alt="card-user" />
                                        <h4>Financial Assets<br></br> Tokenization</h4>
                                    </div>
                                    <figcaption className='trasera'>
                                        <p>
                                        Tokenize your financial assets and get access to better investments on Real Estate, Equity Shares, Gold and more.
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    {/* <Card>
                        <div>
                            <Card.Img variant="top" src={Minero} />
                        </div>
                        <Card.Body>
                            <Card.Title>NFT Minting <br></br>Solutions</Card.Title>
                            <Card.Text>
                                Manage and customize your NFTs with a modern, interactive and secure interface.
                            </Card.Text>
                        </Card.Body>
                    </Card> */}

                    <div className='contenedor'>
                        <div className='contenedor_tarjeta'>
                            <div className='remplazohref'>
                                <figure>
                                    <div className='frontal'>
                                        <img src={Minero} className='fluid' alt="card-user" />
                                        <h4>NFT Minting <br></br>Solutions</h4>
                                    </div>
                                    <figcaption className='trasera'>
                                        <p>
                                        Manage and customize your NFTs with a modern, interactive and secure interface.
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    {/* <Card>
                        <div>
                            <Card.Img variant="top" src={TuercaLampara} />
                        </div>
                        <Card.Body>
                            <Card.Title>Decentralized Apps,<br></br> DAOs & DeFi </Card.Title>
                            <Card.Text>
                                Create your fully customizable solution developed through Smart Contract technology.
                            </Card.Text>
                        </Card.Body>
                    </Card> */}

                    <div className='contenedor'>
                        <div className='contenedor_tarjeta'>
                            <div className='remplazohref'>
                                <figure>
                                    <div className='frontal'>
                                        <img src={TuercaLampara} className='fluid' alt="card-user" />
                                        <h4>Decentralized Apps,<br></br> DAOs & DeFi</h4>
                                    </div>
                                    <figcaption className='trasera'>
                                        <p>
                                        Create your fully customizable solution developed through Smart Contract technology.
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    {/* <Card>
                        <div>
                            <Card.Img variant="top" src={Billetera} />
                        </div>
                        <Card.Body>
                            <Card.Title>ICO & IDO <br></br>Projects</Card.Title>
                            <Card.Text>
                                Get quick funds for your startup with ICO and IDO projects development.
                            </Card.Text>
                        </Card.Body>
                    </Card> */}

                    <div className='contenedor'>
                        <div className='contenedor_tarjeta'>
                            <div className='remplazohref'>
                                <figure>
                                    <div className='frontal'>
                                        <img src={Billetera} className='fluid' alt="card-user" />
                                        <h4>ICO & IDO <br></br>Projects</h4>
                                    </div>
                                    <figcaption className='trasera'>
                                        <p>
                                            Get quick funds for your startup with ICO and IDO projects development.
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Cards