import React, { useEffect, useState } from 'react'

import Team from '../components/team/team.jsx';
import Footer from '../components/footer/footer.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../i18n"
import BodyIntro from '../components/body-intro/body-intro';
import OurServices from '../components/our-services/our-services';
import Cards from '../components/cards/cards';
import ContactUs from '../components/contact-us/contact-us';
import CarouselBlog from '../components/blog-carousel/blog-carousel';
import ServicesCarousel from '../components/services-carousel/services-carousel';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from "react-i18next";
import logo from '../assets/images/logo-principal.png';
import { useMediaQuery } from 'react-responsive';
import ContactUsForm from '../components/contact-us-form/contact-us-form.jsx';
import BigVideo from '../components/bigVideo/BigVideo.jsx';

const Home = () => {

    const { t, i18n } = useTranslation();
    const [isNavbarSmall, setIsNavbarSmall] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: 768 });


    // const changeLanguageHandler = (e) => {
    //     const languageValue = e.target.value
    //     i18n.changeLanguage(languageValue);
    // }

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 0) {
                setIsNavbarSmall(true);
            } else {
                setIsNavbarSmall(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    


    return (
        <>
            <section id="header">
                <Navbar variant="dark" expand="lg" fixed="top" className={isDesktop && isNavbarSmall ? 'small-navbar' : !isDesktop ? '' : 'normal-navbar'}>
                    <Container>
                        <Navbar.Brand href="/"><img src={logo} alt='logo dappsfactory'/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" style={{backgroundColor: '#0d014d'}}>
                            <Nav className="align-items-center">
                                <Nav.Link href="#home">{t('Home')}</Nav.Link>
                                <Nav.Link onClick={() => window.location.replace("/#services")}>{t('Services')}</Nav.Link>
                                <Nav.Link onClick={() => window.location.replace("/#clients")}>{t('Clients')}</Nav.Link>
                                <Nav.Link onClick={() => window.location.replace("/tokenizacion")}>{t('Tokenización')}</Nav.Link>
                                <Nav.Link onClick={() => window.location.replace("/#contact")}>
                                    <div className="contact-navbar-button">
                                        <button className="buttont">Contact us</button>
                                    </div>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </section>
            <section id="body" style={{ paddingTop: '25vh' }}>
                <BodyIntro />

                <div id="services" style={{ scrollMarginTop: '15vh' }}>
                    <OurServices />
                </div>
                <Cards />
                <ContactUs />
                    <BigVideo />
                <div id="clients" style={{ scrollMarginTop: '15vh' }}>
                    <ServicesCarousel  />
                </div>
                <Team />
                <CarouselBlog />
                <div className='contactUs-overwrite' id='contact' style={{ scrollMarginTop: '8vh' }}>
                    <ContactUsForm />

                </div>
            </section>
            <section id="footer">
                <Footer />
            </section>
        </>
    );
};

export default Home;

