import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/images/logo-principal.png";
import raya from "../../assets/images/Division Footer.svg";
import Verified from "../../assets/images/verifiedBlue.png";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <img src={raya} alt="raya de colores" className="raya"></img>
      <Container>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col lg={4} md={4} sm={12}>
            <img src={logo} className="logo-footer" alt="logo dappsfactory" />
          </Col>
          <Col lg={8} md={8} sm={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div>
                <a
                  href="https://www.designrush.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Verified}
                    className="logo-footer"
                    alt="logo dappsfactory"
                    style={{ width: "4rem", marginRight: "2rem" }}
                  />
                </a>
              </div>
              <div>
                <h5>Contact</h5>
                <a
                  href="mailto:hello@dappsfactory.io"
                  style={{ textDecoration: "none", color: "primary" }}
                >
                  hello@dappsfactory.io
                </a>
                <p style={{ marginTop: "1rem" }}>Tel. +54 11-7079-1777</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
