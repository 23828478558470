import React from 'react'
import FotoHoracio from '../../assets/images/team/horacio01.svg';
import FotoNatalia from '../../assets/images/team/Natalia01.svg';
import FotoJuanma from '../../assets/images/team/Juanma01.svg';
import FotoTomas from '../../assets/images/team/Tomi01.svg';
import FotoCorsico from '../../assets/images/team/corsico01.svg';
import FotoTato from '../../assets/images/team/franco01.svg';
import FotoGabriel from '../../assets/images/team/Gabriel01.svg';
import FotoBruno from '../../assets/images/team/bruno01.svg';
import FotoSebastian from '../../assets/images/team/sebastian01.svg';
import FotoCeit from '../../assets/images/team/Ceit01.svg';
import FotoCufa from '../../assets/images/team/Facundo01.svg';
import FotoAndy from '../../assets/images/team/Andres01.svg';
import FotoLucas from '../../assets/images/team/Lucas01.svg';
import FotoGero from '../../assets/images/team/Gero01.svg';
import FotoLiara from '../../assets/images/team/Liara01.svg';
import FotoMatias from '../../assets/images/team/Matias01.svg';
import FotoGaby from '../../assets/images/team/Gaby01.svg';
import FotoJoaquin from '../../assets/images/team/Joaquin01.svg';
import FotoAle from '../../assets/images/team/Ale01.svg';
import FotoMoyano from '../../assets/images/team/Moyano01.svg';

import FotoIAHoracio from '../../assets/images/team/horacio02.svg';
import FotoIANatalia from '../../assets/images/team/Natalia02.svg';
import FotoIAJuanma from '../../assets/images/team/Juanma02.svg';
import FotoIATomas from '../../assets/images/team/Tomi02.svg';
import FotoIACorsico from '../../assets/images/team/corsico02.svg';
import FotoIATato from '../../assets/images/team/franco02.svg';
import FotoIAGabriel from '../../assets/images/team/Gabriel02.svg';
import FotoIABruno from '../../assets/images/team/bruno02.svg';
import FotoIASebastian from '../../assets/images/team/sebastian02.svg';
import FotoIACeit from '../../assets/images/team/Ceit02.svg';
import FotoIACufa from '../../assets/images/team/Facundo02.svg';
import FotoIAAndy from '../../assets/images/team/Andres02.svg';
import FotoIALucas from '../../assets/images/team/Lucas02.svg';
import FotoAIGero from '../../assets/images/team/Gero02.svg';
import FotoAILiara from '../../assets/images/team/Liara02.svg';
import FotoAIMatias from '../../assets/images/team/Matias02.svg';
import FotoAIGaby from '../../assets/images/team/Gaby02.svg';
import FotoAIJoaquin from '../../assets/images/team/Joaquin02.svg';
import FotoAIAle from '../../assets/images/team/Ale02.svg';
import FotoAIMoyano from '../../assets/images/team/Moyano02.svg';

import './team.css';

const team = () => {
    return (
        <div className="container">
            <div className="team">
                <div className="teamTitulo">
                    <h2>OUR FOUNDER TEAM</h2>
                    <p>Is made up of a group of experienced professionals in the IT business areas.</p>
                </div>
                <div className="fotos">
                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoHoracio} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIAHoracio} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>HORACIO LAMPE</p>
                            <p>CEO</p>
                        </div>
                    </div>

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoAndy} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIAAndy} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>ANDRES KÜHNEL</p>
                            <p>Comercial Manager</p>
                        </div>
                    </div>

                    <div className="card1">

                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoNatalia} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIANatalia} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='content-personal'>
                            <p>NATALIA MATEU</p>
                            <p>Graphic Designer</p>
                        </div>
                    </div>

                    <div className="card1">

                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoJuanma} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIAJuanma} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='content-personal'>
                            <p>JUAN MANUEL PACHECO</p>
                            <p>Project Manager & UX/UI</p>
                        </div>
                    </div>

                    

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoCorsico} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIACorsico} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>TOMÁS CÓRSICO</p>
                            <p>Solidity & Back end Programmer</p>
                        </div>
                    </div>

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoTato} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIATato} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>FRANCO BELLAZZI</p>
                            <p>Solidity & Back end Programmer</p>
                        </div>
                    </div>

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoGabriel} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIAGabriel} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>GABRIEL RE</p>
                            <p>Solidity & Back end Programmer</p>
                        </div>
                    </div>

                    

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoBruno} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIABruno} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>BRUNO NERPITI</p>
                            <p>Solidity & Back end Programmer</p>
                        </div>
                    </div>

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoSebastian} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIASebastian} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='content-personal'>
                            <p>SEBASTIÁN MEDINA</p>
                            <p>React Js. Ether. Js Programmer</p>
                        </div>
                    </div>

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoCeit} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIACeit} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='content-personal'>
                            <p>TOMÁS CEIT</p>
                            <p>React Js. Ether. Js Programmer</p>
                        </div>
                    </div>

                    

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoLucas} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoIALucas} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>Lucas Demarchi</p>
                            <p>Solidity & Back end Programmer</p>
                        </div>
                    </div>

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoGero} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoAIGero} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>Gerónimo Gallinger</p>
                            <p>Back end Programmer</p>
                        </div>
                    </div>

              

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoMoyano} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoAIMoyano} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>Lucas Moyano</p>
                            <p>Back end Programmer</p>
                        </div>
                    </div>

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoLiara} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoAILiara} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>Liara Regina Agulló</p>
                            <p>Marketing</p>
                        </div>
                    </div>

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoMatias} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoAIMatias} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>Matias Martos Tano</p>
                            <p>AI developer</p>
                        </div>
                    </div>


                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoGaby} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoAIGaby} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>Gabriel Manyari</p>
                            <p>Back end Programmer</p>
                        </div>
                    </div>

                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoJoaquin} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoAIJoaquin} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>Joaquin Vega Majul</p>
                            <p>Back end Programmer</p>
                        </div>
                    </div>


                    <div className="card1">
                        <div className='img-circle'>
                            <div className='contenedor'>
                                <div className='contenedor_tarjeta'>
                                    <div className='remplazohref'>
                                        <figure>
                                            <div className='frontal'>
                                                <img src={FotoAle} alt="" className="foto" />
                                            </div>
                                            <figcaption className='trasera'>
                                                <img src={FotoAIAle} className='fluid' alt="card-user" />
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-personal'>
                            <p>Alejandro Leupold de Souza</p>
                            <p>Jr AI developer</p>
                        </div>
                    </div>





                </div>
            </div>
        </div>
    )
}

export default team