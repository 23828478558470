import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import Separador from '../../assets/images/separador-03 1.png';

import './contact-us.css';
import { Link } from 'react-router-dom';

const ContactUs = () => {
    return (
        <div className='pt-5 pb-3 contact-us-section'>
            <Container>
                <Row>
                    <Col md={5} className='contact-us-button'>
                        
                        <button onClick={() => window.location.replace("/#contact")}>
                            Contact Us
                        </button>

                       
                    </Col>
                </Row>
            </Container>
            <img src={Separador} alt="" className="foto" />

        </div>
    )
}

export default ContactUs