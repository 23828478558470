import React from 'react'

import Header from '../components/header/header.jsx';
import Footer from '../components/footer/footer.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../i18n"
import ContactUsForm from '../components/contact-us-form/contact-us-form';

const Contact = () => {
    return (
        <>
            <section id="header">
                <Header />
            </section>
            <section id="body">
                <ContactUsForm id="contactUs" />
            </section>
            <section id="footer">
                <Footer />
            </section>
        </>
    );
};

export default Contact;