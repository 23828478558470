import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Ilustracion from '../../assets/images/Ilustracion.svg';
import Etherum from '../../assets/images/Logo_etwreum.svg';
import Polygon from '../../assets/images/Logo_polygon.svg';
import Binance from '../../assets/images/Logo_binance.svg';
import Avalanche from '../../assets/images/Logo_avalance.svg';
import Arbitrum from '../../assets/images/Logo_arbitrum.svg';
import './our-services.css';


const OurServices = () => {
    return (
        <Container className="our-services">
            <h2>OUR SERVICES</h2>
            <h6 className='subtitle'>Transform your ideas into a concrete and viable Blochain project. </h6>
            <Row>
                <Col xl={5} lg={7} xs={12} className='d-flex'>
                    <img src={Ilustracion} alt="" className="foto foto-stonks" />
                </Col>
                <Col xl={7} lg={5} xs={12} className='acomodo'>
                    <div>
                        <p>We are a knowledge company focused on building solutions on Blockchain. We transform your ideas into a concrete and viable Blockchain project.</p>
                    </div>
                    <div>
                        <img src={Etherum} alt="" className="foto" />
                        <img src={Polygon} alt="" className="foto" />
                        <img src={Binance} alt="" className="foto" />
                        <img src={Avalanche} alt="" className="foto" />
                        <img src={Arbitrum} alt="" className="foto" />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default OurServices