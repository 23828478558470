import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Container, Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import UNO from "../../assets/images/blog-1.png";
import DOS from "../../assets/images/blog-2.png";
import TRES from "../../assets/images/blog-3.png";
import CUATRO from "../../assets/images/blog-4.png";
import CINCO from "../../assets/images/blog-5.png";
import SEIS from "../../assets/images/blog-6.png";
import SIETE from "../../assets/images/blog-7.png";
import OCHO from "../../assets/images/blog-8.png";
import NUEVE from "../../assets/images/blog-9.png";

import "./blog-carousel.css";

const CarouselBlog = () => {
  return (
    // <Container >
    <>
      <div className="blog d-none d-lg-block">
        <h2>BLOG</h2>
        <h6 className="subtitle">
          {" "}
          Transform your ideas into a concrete and viable Blockchain project.{" "}
        </h6>
        <Carousel interval={null} pause="hover">
          <Carousel.Item className="carousel-blog">
            <Row md={3} xs={1}>
              <Col>
                <Card>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/2wrv_MoJpKE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <Card.Body>
                    <Card.Title>
                      Diplomas and certificates being recorded on a blockchain
                    </Card.Title>
                    <Card.Text>
                      In this captivating video, we will explore the fascinating
                      world of Blockchain Diplomas and Certificates,
                      revolutionizing the future of education. Join us as we
                      explore the benefits of digital credentials with digital
                      signatures and their integration into the immutable
                      Blockchain. Learn how this innovative technology ensures
                      secure, tamper-proof, and globally recognized educational
                      achievements. Don't miss the opportunity to be at the
                      forefront of the digital age of learning. Look now!
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="https://www.youtube.com/watch?v=2wrv_MoJpKE"
                        target="_blank"
                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/V4OHkCEuLvs"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <Card.Body>
                    <Card.Title>Vertical Segments</Card.Title>
                    <Card.Text>
                      In this video, we will explore the advantages of
                      registering events, documents, and certificates on the
                      blockchain. Have you ever thought about the immutability
                      of records? Blockchain provides just that: a secure and
                      immutable way to store information. Let's look at some key
                      advantages:
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="https://www.youtube.com/watch?v=V4OHkCEuLvs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/_U3oBJZaI2I"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <Card.Body>
                    <Card.Title>
                      What is blockchain middleware? - Blockchain Middleware
                      Connectivity
                    </Card.Title>
                    <Card.Text>
                      Dapps are applications that run on a blockchain network
                      and are based on smart contracts to ensure
                      decentralization and transparency. These applications are
                      attractive to users due to their decentralized nature,
                      which means they don't depend on a single entity for
                      operation and are resistant to censorship and
                      manipulation. Dapp's Factory is a company that specializes
                      in integrating and connecting legacy systems with
                      blockchain technology. Look now!
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="https://www.youtube.com/watch?v=_U3oBJZaI2I"
                        target="_blank"
                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-blog">
            <Row md={3} xs={1}>
              <Col>
                <Card>
                  <Card.Img variant="top" src={UNO} />
                  <Card.Body>
                    <Card.Title>
                      DAppsFactory is betting on global expansion with Georgia
                      Tech's Soft Landings program
                    </Card.Title>
                    <Card.Text>
                      On Tuesday, <b>February 14th,</b> our team had a meeting
                      with the Amazon Startups team to discuss the architecture
                      of a solution we are proposing for a major client in
                      Argentina. We are thrilled to have the opportunity to
                      collaborate with such a respected company and work towards
                      delivering a top-notch solution for our client.
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="https://blockworks.co/news/amazon-nfts-real-world-assets-token"
                        target="_blank"

                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img variant="top" src={DOS} />
                  <Card.Body>
                    <Card.Title>
                      Exciting news from DappsFactory! meeting with the Amazon
                      Startups team
                    </Card.Title>
                    <Card.Text>
                      <b> Blockchain technology</b>has the potential to
                      revolutionize the way products are tracked and traced
                      throughout their lifecycle. By implementing a
                      decentralized, tamper-proof ledger, blockchain allows for
                      unparalleled transparency and traceability in supply chain
                      management.
                    </Card.Text>
                    <Button variant="primary">
                      <a href="#" target="_blank">
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img variant="top" src={TRES} />
                  <Card.Body>
                    <Card.Title>
                      Implementing blockchain technology in educational record
                      management: An investment worth making
                    </Card.Title>
                    <Card.Text>
                      Blockchain technology has been gaining ground in various
                      fields, one of which is the security and authenticity of
                      documents. In particular, the use of blockchain for
                      managing study certificates and diplomas has gained
                      significance in recent years, as they are important
                      documents that can be subject to counterfeiting and fraud.{" "}
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="https://www.linkedin.com/pulse/blockchain-technology-its-importance-product-traceability/?trackingId=70qNfgNjx1iG5qSbX5vzjQ%3D%3D"
                        target="_blank"
                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-blog">
            <Row md={3} xs={1}>
              <Col>
                <Card>
                  <Card.Img variant="top" src={CUATRO} />
                  <Card.Body>
                    <Card.Title>
                      Blockchain Technology and Its Importance in Product
                      Traceability: Benefits for Logistics and Laboratory
                      Companies
                    </Card.Title>
                    <Card.Text>
                      Blockchain technology has rapidly gained attention in
                      recent years, particularly in the realm of product
                      traceability. Its ability to provide an immutable and
                      transparent record of transactions has led to its
                      application in a range of industries, from supply chain
                      management to healthcare.{" "}
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="https://www.linkedin.com/pulse/blockchain-technology-its-importance-product-traceability/?trackingId=70qNfgNjx1iG5qSbX5vzjQ%3D%3D"
                        target="_blank"
                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img variant="top" src={CINCO} />
                  <Card.Body>
                    <Card.Title>
                      The Importance of Blockchain in Event Ticketing
                    </Card.Title>
                    <Card.Text>
                      As the events industry continues to grow, ticket fraud and
                      scalping have become increasingly prevalent issues.
                      Traditional ticketing systems have proven to be vulnerable
                      to fraudulent activities, leading to lost revenue and
                      dissatisfied customers. However, the integration of
                      blockchain technology in the event ticketing industry has
                      brought about a new level of security and transparency.
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="https://www.linkedin.com/pulse/importance-blockchain-event-ticketing-dappsfactory/?trackingId=cwWp9Q8xd1UWPjxZKWshAQ%3D%3D"
                        target="_blank"
                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img variant="top" src={SEIS} />
                  <Card.Body>
                    <Card.Title>Piece of Cake Wallet</Card.Title>
                    <Card.Text>
                      We, at DAppsFactory, have already developed a
                      user-friendly NFT WALLET, easy to use, with an intuitive
                      interface and a pleasant user experience. You can VIEW
                      NFTs in a simple way (Not for techies), CREATE your own
                      NFTs by taking a picture with geolocalized Metadata or
                      from a file saved on your phone or PC. SHARE, RECEIVE and
                      SEND NFTs to people with or without Wallet. You can also
                      PUBLISH NFTs on Social Networks.
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="#https://www.linkedin.com/pulse/piece-cake-wallet-dappsfactory/?trackingId=fgMYZHBdE%2FMjg%2FKkvJl81Q%3D%3D"
                        target="_blank"
                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-blog">
            <Row md={3} xs={1}>
              <Col>
                <Card>
                  <Card.Img variant="top" src={SIETE} />
                  <Card.Body>
                    <Card.Title>
                      Security in Blockchain Projects: How Blockchain Technology
                      Ensures Integrity and Trust in Information
                    </Card.Title>
                    <Card.Text>
                      The blockchain technology is known to be one of the most
                      secure technologies in the digital world. But why is it
                      more secure than other technologies? The answer lies in
                      the very structure of blockchain technology. Instead of
                      having a centralized server that stores all the
                      information ...
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="https://www.linkedin.com/pulse/security-blockchain-projects-how-technology-ensures-integrity/?trackingId=H1IDoFIWOrlezEkgsEfa2Q%3D%3D"
                        target="_blank"
                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img variant="top" src={OCHO} />
                  <Card.Body>
                    <Card.Title>
                      Unlocking the Potential of Blockchain: DAppsFactory's
                      Consulting Services for Decentralized Application
                      Development
                    </Card.Title>
                    <Card.Text>
                      DAppsFactory is a technology company that has been
                      developing software solutions for more than 20 years.
                      Focused on building business solutions on the Blockchain,
                      the company transforms customers' ideas into concrete and
                      viable Blockchain projects.
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="https://www.linkedin.com/pulse/unlocking-potential-blockchain-dappsfactorys-consulting-services/?trackingId=f6pusSaU18BSo%2FroebV%2FNg%3D%3D"
                        target="_blank"
                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img variant="top" src={NUEVE} />
                  <Card.Body>
                    <Card.Title>
                      Pieceofcakewallet: How User-Friendly Interface Can
                      Accelerate Blockchain Adoption
                    </Card.Title>
                    <Card.Text>
                      The dilemma of introducing a new technology like
                      blockchain is multi-faceted, but one key aspect is whether
                      to focus on educating the common user about the technology
                      or improving the user interfaces to facilitate adoption.
                      Both options have their merits and drawbacks, and
                      ultimately, the optimal approach may depend on the
                      circumstances.
                    </Card.Text>
                    <Button variant="primary">
                      <a
                        href="https://www.linkedin.com/pulse/pieceofcakewallet-how-user-friendly-interface-can-accelerate-/?trackingId=XlS1yox8UQZCnAgQnX711w%3D%3D"
                        target="_blank"
                        rel="noreferrer"
                      >
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="blog d-lg-none">
        <Carousel>
          <Carousel.Item className="carousel-blog">
            <Row>
              <Col>
                <Card>
                  <Card.Img variant="top" src={UNO} />
                  <Card.Body>
                    <Card.Title>
                      Lowering Barriers to Entry: How Tokenization Makes
                      Investing More Accessible
                    </Card.Title>
                    <Card.Text>
                      Tokenization is the process of converting a physical
                      asset, such as real estate or gold, into a digital token
                      that can be traded on a blockchain. This allows for
                      fractional ownership and increased liquidity of the asset,
                      as it can be bought and sold easily on a digital
                      marketplace.
                    </Card.Text>
                    <Button variant="primary">
                      <a href="#" target="_blank">
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-blog">
            <Row>
              <Col>
                <Card>
                  <Card.Img variant="top" src={DOS} />
                  <Card.Body>
                    <Card.Title>
                      Tracking Products with Blockchain: A Look into the Future
                      of Supply Chain Management
                    </Card.Title>
                    <Card.Text>
                      Blockchain technology has the potential to revolutionize
                      the way products are tracked and traced throughout their
                      lifecycle. By implementing a decentralized, tamper-proof
                      ledger, blockchain allows for unparalleled transparency
                      and traceability in supply chain management.
                    </Card.Text>
                    <Button variant="primary">
                      <a href="#" target="_blank">
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-blog">
            <Row>
              <Col>
                <Card>
                  <Card.Img variant="top" src={TRES} />
                  <Card.Body>
                    <Card.Title>
                      Lowering Barriers to Entry: How Tokenization Makes
                      Investing More Accessible
                    </Card.Title>
                    <Card.Text>
                      Tokenization is the process of converting a physical
                      asset, such as real estate or gold, into a digital token
                      that can be traded on a blockchain. This allows for
                      fractional ownership and increased liquidity of the asset,
                      as it can be bought and sold easily on a digital
                      marketplace.
                    </Card.Text>
                    <Button variant="primary">
                      <a href="#" target="_blank">
                        READ MORE
                      </a>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default CarouselBlog;
