import React from "react";
import "./BigVideo.css";
import {Container } from 'react-bootstrap'

const BigVideo = () => {
  return (
    <Container className="video-container">
      <div className="our-services">
        <h2>BLOCKCHAIN CERTIFICATES</h2>
      </div>
      <iframe 
        width="854"
        height="480"
        src="https://www.youtube.com/embed/2wrv_MoJpKE"
        title="BLOCKCHAIN CERTIFICATES"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        
      ></iframe>
    </Container>
  );
};

export default BigVideo;
