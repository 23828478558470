import React from 'react'

import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col } from 'react-bootstrap';

import './modal-client.css';

const modalClient = (props) => {
    return (
        <Modal {...props}  size="lg">
            <Container>
                <Row>
                    <Col md={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }}>
                        <img src={props.cellphones} alt="" className="foto" />
                    </Col>
                    <Col md={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                        <img src={props.logo} alt="" className="foto" />
                            {props.urlSite === '' ? <p>{props.urlSite}</p> : <a href={props.urlSite} target='_blank'>{props.urlSite}</a>}
                    </Col>
                </Row>
                <div className='grid-fotos'>
                    <img src={props.firstDesktop} alt="" className="foto" />
                    <img src={props.secondDesktop} alt="" className="foto" />
                    {props.thirdDesktop === '' ? <div className="d-none"></div> : <img src={props.thirdDesktop} alt="" className="foto d-none d-sm-block" />}
                    {props.fourthDesktop === '' ? <div className="d-none"></div> : <img src={props.fourthDesktop} alt="" className="foto d-none d-sm-block" />}
                </div>
            </Container>
        </Modal>
    )
}

export default modalClient