import React from 'react'
// import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import Form from 'react-bootstrap/Form';
import { useTranslation } from "react-i18next";
import logo from '../../assets/images/logo-principal.png';
import './header.css';
import { Container } from 'react-bootstrap';

const Header = () => {
    const { t, i18n } = useTranslation();

    // const changeLanguageHandler = (e) => {
    //     const languageValue = e.target.value
    //     i18n.changeLanguage(languageValue);
    // }

    return (
        <Navbar variant="dark" expand="lg">
            <Container>
                <Navbar.Brand href="/"><img src={logo} alt='logo dappsfactory' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <Nav.Link href="#home">{t('Home')}</Nav.Link>
                        <Nav.Link onClick={() => window.location.replace("/#products")}>{t('Products')}</Nav.Link>
                        <Nav.Link onClick={() => window.location.replace("/#clients")}>{t('Clients')}</Nav.Link>
                        <Nav.Link href="/contact" target="_blank">{t('Contact us')}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
export default Header
