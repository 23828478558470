import React from "react";

const TokenizacionComponent = () => {
  return (
      <iframe
        src="https://actionfintech.com.ar/tokenizacion"
        frameBorder="0"
        
        style={{ display: "block", width: "100%", height: "100dvh" }}
      ></iframe>
  );
};

export default TokenizacionComponent;
