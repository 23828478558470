import React, {useEffect} from "react";
import { Container } from 'react-bootstrap';

import './contact-us-form.css';

const ContactUsForm = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "23120215",
                    formId: "a6d35689-eac6-445b-a2ef-b1551e4f6819",
                    target: "#hubspotForm"
                })
            }
        });
    }, []);

    return (
        <div className='pt-5 pb-3 contact-us-form-section'>
            <h2>CONTACT US</h2>
			<Container>
                <div id="hubspotForm"></div>
            </Container>
        </div>
    )
}

export default ContactUsForm